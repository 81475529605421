@font-face {
  font-family: Roboto;
  src: url('./styles/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('./styles/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}


@font-face {
  font-family: Roboto;
  src: url('./styles/fonts/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url('./styles/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}

html {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Roboto, sans-serif;
}


body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


.u-lock-scroll {
  overflow: hidden !important;
}


ul {
  margin: 0;
}
